import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Box, Typography, Paper, Stack, TextField, Tooltip } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { updateMachineModel, createMachineModel } from "../../../features/machines/actions";
import { readAsyncStorageValues } from "../../../features/common/actions";

const AddEditMachineModel = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { machineModels } = useSelector((state) => state.common);
  const [enableEdit, setEnableEdit] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(readAsyncStorageValues());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      const selectedModel = machineModels.find((item) => item.id == id) || {};
      let infoData = selectedModel["info "] || selectedModel.info; // Handle inconsistent key
  
      if (typeof infoData === "string") {
        try {
          infoData = JSON.parse(infoData); // Parse if it's a string
        } catch (error) {
          console.error("Error parsing info JSON:", error);
          infoData = {}; // Default to empty object if parsing fails
        }
      }
  
      setFormData({
        ...selectedModel,
        info: {
          ...infoData, // Ensure it's an object
          notes: infoData?.notes || "", // Default empty notes
        },
      });
    }
  
    if (state?.enableEdit || !id) setEnableEdit(true);
  }, [id, machineModels, state]);
  
  const handleSaveButton = () => {
    if (!formData.manufacturer || !formData.model) {
      setErrors({
        model: formData.model || "Please enter model!",
        manufacturer: formData.manufacturer || "Please enter manufacturer!",
      });
      return toast.warn(` Please enter required fields! `);
    }

    const updateObj = {
      manufacturer: formData.manufacturer,
      modelName: formData.model,
      info: JSON.stringify({
        notes: formData.info?.notes || "",
      }),
    };    

    // Find an existing entry with the same manufacturer & model, excluding the current entry
    const existingModel = machineModels.find(
      (item) =>
        item.model.toLowerCase() === updateObj.modelName.toLowerCase() &&
        item.manufacturer.toLowerCase() === updateObj.manufacturer.toLowerCase() &&
        item.id !== id
    );

    // Allow update if only the notes field is changed
    if (existingModel) {
      const existingNotes = JSON.parse(existingModel.info || "{}").notes || "";
      if (existingNotes === formData.info?.notes) {
        return toast.warn(`Model already exists with the same manufacturer and model name!`);
      }
    }

    if (id) {
      updateObj.modelId = id;
      submitForm(updateMachineModel, updateObj);
    } else {
      submitForm(createMachineModel, updateObj);
    }
  };

  const submitForm = (action, updateObj) => {
    dispatch(action(updateObj)).then((res) => {
      dispatch(readAsyncStorageValues());
      setEnableEdit(false);
      if (res?.payload?.status && res?.payload?.data) {
        navigate(`/edit-machine-model/` + res.payload.data.id, { replace: true });
        return toast.success(` Machine Model ${id ? "updated" : "created"}`, { autoClose: 1000 });
      } else return toast.warn(` Please try again!`);
    });
  };
  return (
    <div>
      {enableEdit ? (
        <Stack direction={"row"} sx={{ marginY: 5, marginLeft: "4%" }}>
          <Stack gap={1} flexWrap={"wrap"} sx={{ marginRight: 2 }}>
            <TextField
              style={{ boxShadow: "0px 3px 15px rgba(0,0,0,0.1)" }}
              value={formData.manufacturer}
              id="manufacturer"
              label="Manufacturer"
              name="manufacturer"
              InputProps={{
                inputProps: { maxLength: 32 },
              }}
              onChange={(newValue) => setFormData({ ...formData, manufacturer: newValue.target.value.toUpperCase() })}
              sx={{ minWidth: 350 }}
              error={!formData.manufacturer && errors.manufacturer}
              helperText={!formData.manufacturer && errors.manufacturer}
            />
            <Typography variant="caption" color="textSecondary" sx={{ marginLeft: 25}}>
              {32 - (formData.manufacturer?.length || 0)} characters remaining
            </Typography>
            <TextField
              style={{ boxShadow: "0px 3px 15px rgba(0,0,0,0.1)" }}
              margin="normal"
              value={formData.model}
              id="machine model"
              label="Machine Model"
              name="modelName"
              InputProps={{
                inputProps: { maxLength: 32 },
              }}
              onChange={(newValue) => setFormData({ ...formData, model: newValue.target.value.toUpperCase() })}
              sx={{ minWidth: 350 }}
              error={!formData.model && errors.model}
              helperText={!formData.model && errors.model}
            />
            <Typography variant="caption" color="textSecondary" sx={{ marginLeft: 25 }}>
              {Math.max(0, 32 - (formData.model?.length || 0))} characters remaining
            </Typography>
            <TextField
              style={{ boxShadow: "0px 3px 15px rgba(0,0,0,0.1)" }}
              margin="normal"
              value={formData.info?.notes || ""}
              id="notes"
              label="Notes"
              name="notes"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  info: {
                    ...formData.info,
                    notes: e.target.value,
                  } || {},
                })
              }              
              sx={{ minWidth: 350 }}
            />

          </Stack>
          <Stack>
            <Tooltip title="Save">
              <Button variant="contained" onClick={handleSaveButton}>
                Save
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
      ) : (
          <Box sx={{ mx: 3, marginY: 2, marginLeft: "3%" }}>
            <Paper sx={{ borderRadius: 2, mb: 1.5, p: 3 }}>
              <Stack justifyContent={"space-between"} direction={"row"} flexWrap={"wrap"}>
                <Stack rowGap={1}>
                  <Typography>
                    <span style={{ fontWeight: "bold" }}>Manufacturer:</span> {formData.manufacturer}
                  </Typography>
                  <Typography>
                    <span style={{ fontWeight: "bold" }}>Model:</span> {formData.model}
                  </Typography>
                  <Typography>
                    <span style={{ fontWeight: "bold" }}>Notes:</span> {formData.info?.notes || ""}
                  </Typography>
                </Stack>
                <Stack rowGap={1}>
                  <Tooltip title="Edit model">
                    <Button onClick={() => setEnableEdit(true)} variant="contained">
                      Edit
                    </Button>
                  </Tooltip>
                </Stack>
              </Stack>
            </Paper>
          </Box>
      )}
      <ToastContainer />
    </div>
  );
};

export default AddEditMachineModel;
