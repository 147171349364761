import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTemplates, deleteTemplate } from "../../features/templates/actions";
import { readAsyncStorageValues } from "../../features/common/actions";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Search } from "@mui/icons-material";
import { ZoomIn as ViewIcon, Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  Stack,
  Autocomplete,
  TextField,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Button,
  Typography,
  Box,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  Card,
} from "@mui/material";

import axios from "axios";

const Templates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const { asyncStorageValues } = useSelector((state) => state.common);
  const { allTemplates } = useSelector((state) => state.templates);
  const [searchValue, setSearchValue] = useState("");
  const [templatesList, setTemplatesList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllTemplates());
    if (!asyncStorageValues) dispatch(readAsyncStorageValues());
  }, [dispatch]);
  useEffect(() => {
    if (allTemplates.data.length && !allTemplates.isLoading) {
      setTemplatesList(allTemplates.data);
    }
  }, [allTemplates.data]);

  const handleOpenDialog = (template) => {
    setTemplateToDelete(template);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setTemplateToDelete(null);
  };

  const handleDeleteConfirmed = async () => {
    if (templateToDelete) {
      await dispatch(deleteTemplate({ id: templateToDelete.id }));
      dispatch(getAllTemplates());
    }
    handleCloseDialog();
  };
  const { create_edit_templates } = asyncStorageValues?.configData?.dataOwnerPermissions;
  const columns = [
    { field: "name", headerName: "Name", width: 100, flex: 0.1 },
    { field: "periodicity", headerName: "Periodicity", width: 100, flex: 0.1 },
    {
      field: "active",
      headerName: "Active",
      width: 150,
      flex: 0.05,
      valueGetter: (params) => (params.row[params.field] ? "Yes" : "No"),
    },

    {
      sortable: false,
      filterable: false,
      flex: 0.04,
      renderCell: (args) => (
        <Stack direction={"row"}>
          {!create_edit_templates ? (
            <IconButton sx={{ mr: 1.5 }} onClick={() => navigate(`/edit-template/` + args.row.id)}>
              <Tooltip title="View">
                <ViewIcon sx={{ height: "self" }} />
              </Tooltip>
            </IconButton>
          ) : null}
          {create_edit_templates ? (
            <Stack direction={"row"}>
              <IconButton
                sx={{ mr: 1.5 }}
                onClick={() => {
                  navigate(`/add-template/`, { state: { copyTemplate: true, copyTemplateId: args.row.id } });
                }}
                color="primary"
              >
                <Tooltip title="Copy">
                  <FileCopyIcon sx={{ height: "self" }} />
                </Tooltip>
              </IconButton>
              <IconButton sx={{ mr: 1.5 }} onClick={() => navigate(`/edit-template/` + args.row.id)} color="primary">
                <Tooltip title="Edit">
                  <EditIcon sx={{ height: "self" }} />
                </Tooltip>
              </IconButton>
              <IconButton sx={{ mr: 1.5 }} onClick={() => handleOpenDialog(args.row)} color="primary">
                <Tooltip title="Delete">
                  <DeleteIcon sx={{ height: "self" }} />
                </Tooltip>
              </IconButton>
            </Stack>
          ) : null}
        </Stack>
      ),
    },
  ];

  return (
    <div>
      <Box sx={{ mx: 2 }}>
        <Stack direction={"row"} alignItems={"center"} my={2} justifyContent={"space-between"}>
          <Stack direction={"row"} gap={2} ml={1} alignItems={"center"}>
            {create_edit_templates ? (
              <Button
                startIcon={<AddIcon />}
                sx={{ height: "self" }}
                autoCapitalize="none"
                variant="contained"
                onClick={() => navigate("/add-template/")}
              >
                ADD
              </Button>
            ) : null}
            <OutlinedInput
              id="outlined-adornment-password"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
              placeholder="Search..."
              size="small"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ minWidth: 350 }}
            />
          </Stack>
        </Stack>
        <Box sx={{ height: 650 }}>
          <DataGrid
            rows={templatesList.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
            columns={columns}
            pageSize={5}
            localeText={{ noRowsLabel: "No Data." }}
            style={{ boxShadow: "0px 3px 15px rgba(0,0,0,0.2)", marginLeft: 5 }}
          />
        </Box>
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Delete Template</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you sure you want to delete the template "{templateToDelete?.name}"?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button onClick={handleDeleteConfirmed} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Templates;
